import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../shared/service/api.service';
import { ProductimageService } from '../../../shared/service/fileupload.service';

import { ActivatedRoute, Router } from "@angular/router";
import { UserModel, Accounts } from 'src/app/shared/models/datamodel';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  public usercreatepopup: Boolean = false;
  public userdetailspopup: Boolean = false;
  sellerdata: any;
  admindata: any;
  public userdata = new UserModel()
  public newuserdata = new UserModel()
  selectedFiles: any;
  FileUploadStatus: boolean;
  currentFileUpload: any;
  user: any;
  public subscriptionplan: any
  token: any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private router: Router, private imageuploadService: ProductimageService) {

    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.sellerdata != null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.user[0].roleaccess.includes("User")
          if (status == false) {
            this.router.navigate(['/page-not-found'])
          } else {
            this.createAccountForm();
            this.createPermissionForm();
          }
        }
        else if (this.subscriptionplan == "Free") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Starter") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Professional") {
          this.createAccountForm();
          this.createPermissionForm();
        }
        else if (this.subscriptionplan == "Enterprise") {
          this.createAccountForm();
          this.createPermissionForm();
        }
        else {
          this.router.navigate(['/page-not-found'])
        }
      }
      else {
        this.errortext = "Please select a seller"
        this.error = true
      }
    }
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      firstname: [''],
      lastname: [''],
      email: [''],
      password: [''],
      phone: ['']
    })
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
    this.getuserdetails()
  }

  getuserdetails() {

    const payload = {
      token: this.sellerdata[0].token,
      email: this.sellerdata[0].email

    }
    const userid = this.sellerdata[0]._id;

    this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
      this.userdata = res
      console.log(this.userdata)
    })
  }



  addUser() {
    if (this.userdata.gst_number == null || this.userdata.gst_number == undefined || this.userdata.gst_number == "") {
      this.errortext = "Please submit your gst details before process"
      this.error = true
    }
    else {
      this.newuserdata.gst_number = this.userdata.gst_number
      this.newuserdata.gst_file_link = this.userdata.gst_file_link
      this.newuserdata.display_name = this.userdata.display_name
      this.newuserdata.legal_trading_name = this.userdata.legal_trading_name
      this.newuserdata.registration_id_number = this.userdata.registration_id_number
      this.newuserdata.tax_number = this.userdata.tax_number
      this.newuserdata.vat_number = this.userdata.vat_number
      this.newuserdata.logo = this.userdata.logo
      this.newuserdata.organisation_type = this.userdata.organisation_type
      this.newuserdata.manufacturer = this.userdata.manufacturer
      this.newuserdata.userid = this.sellerdata[0]._id
      this.newuserdata.token = this.token
      
      if (this.newuserdata.firstname == null) {
        this.errortext = "please submit first name"
        this.error = true
        // alert("please submit first name")
      } else if (this.newuserdata.lastname == null) {
        this.errortext = "please submit last name"
        this.error = true
        // alert("please submit last name")
      }
      else if (this.newuserdata.email == null) {
        this.errortext = "please submit email"
        this.error = true
        // alert("please submit phone no")
      }
      else if (this.newuserdata.phone == null) {
        this.errortext = "please submit phone no"
        this.error = true
        // alert("please submit email")
      }
      else if (this.newuserdata.password == null) {
        this.errortext = "please submit password"
        this.error = true
        // alert("please submit password")
      }
      
    
      else {
        this.newuserdata.token = this.token
        this.apiService.addaUser(this.newuserdata).subscribe((result) => {
          this.usercreatepopup = true
          // this.success = true;
          // this.successtext = "User created sucessfully"
          // this.router.navigate(['/users/list-user'])

          // alert(result)
        }, (err) => {
          this.userdetailspopup = true
          // alert(err.error)
        })
      }
   
    }
    

  }
  onusercreatepopupsucess(){
    this.usercreatepopup = false
    this.router.navigate(['/users/list-user'])

  }
  onuserdetailspopupsucess(){
    this.userdetailspopup = false
  }

}

