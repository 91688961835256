import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/service/api.service';
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-activity-user',
  templateUrl: './activity-user.component.html',
  styleUrls: ['./activity-user.component.scss']
})
export class ActivityUserComponent implements OnInit {
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false

  public userDetails = [];
  public id: any;
  admindata: any;
  sellerdata: any;
  subscriptionplan: any;
  constructor(private apiService: ApiService, private router: Router) {

    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));

    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.sellerdata != null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.sellerdata[0].roleaccess.includes("User")
          if (status == false) {
            this.router.navigate(['/page-not-found'])
          }
        }
        else if (this.subscriptionplan == "Free") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Starter") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Professional") {
          this.getactivities();
        }
        else {
          this.getactivities();
        }
      }
      else {
        this.errortext = "Please select a seller"
        this.error = true
        this.router.navigate(['/all/users-sellers'])
      }
    }
  }

  ngOnInit(): void {

  }

  getactivities() {
    this.id = JSON.parse(window.localStorage.getItem("url"));
    console.log(this.id)
    if (this.id != null && this.id != '0') {
      const payload = {
        userid: this.admindata._id,
        token: this.admindata.token
      }
      this.apiService.getuseractivitydetailsappuser(this.id.email)
        .subscribe((useractivity: any) => {
          this.userDetails = useractivity;
        });
    }
  }
}
