import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './contactus-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactusComponent } from './contactus.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  declarations: [ContactusComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    FormsModule,
    NgbModule,
    InfiniteScrollModule
  ]
})
export class ContactusModule { }
