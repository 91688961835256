import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'multikart-backend';
}

// const data = {"resetPasswordLink":"747779","role":"admin","roleaccess":[],"_id":"60dc4b08474fcd1f4498c93b","firstname":"surya","lastname":"basak","secretcode":"679580","email":"surya.basak@edgewrapper.com","phone":"8906376002","country":"India","shiprocketpickupaddress":{"pickup_location":"SBNU","name":"Surya Basak","email":"surya.basak@edgewrapper.com","phone":9932104008,"address":"gopinathpur 1","address_2":"Samudragarh, near dhankall","city":"Samudragarh","state":"West Bengal","country":"India","pin_code":713519},"IsActive":true,"createdAt":"2021-06-30T10:44:24.279Z","updatedAt":"2022-03-01T16:44:39.314Z","__v":0,"password":"$2a$10$yFLZlaD6t6Um2triNlrGUOP/ttINmL1h4GHMhlTrybJ5pdwTut6Q2","tokenidfornotification":"","business_description":"","display_name":"tyyy","email2":"surya.basak@edgewrapper.com","facebook":"","legal_trading_name":"","linkedIn":"","logo":null,"organisation_type":null,"physical_address":"","postal_address":"","registration_id_number":"","tax_number":"","telephone":"","twitter":"","vat_number":"","website":"","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MGRjNGIwODQ3NGZjZDFmNDQ5OGM5M2IiLCJpYXQiOjE2NTUyMDc2ODMsImV4cCI6MTY1NTgxMjQ4M30.ihnrQwHW_MxuAsuQI6is4FG-B_JqbuCCn_5Ro8-vm4s"}
// localStorage.setItem("admin-info", JSON.stringify(data));