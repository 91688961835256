<div class='table-responsive'>
    <h6>First Name:{{id.firstname}}; Last Name:{{id.lastname}}</h6>
    <h6>Mobile:{{id.phone}}; Email:{{id.email}}</h6>
    <table class='table table-bordered table-sm '>
        <thead class="thead-dark">
            <tr>
                <th align="center">Token</th>
                <th align="center">Activity</th>
                <th align="center">Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of userDetails">
                <td align="center">New Login </td>
                <td>
                    <table class='table table-sm '>
                        <tbody>
                            <tr *ngFor="let activity of user.activity">
                                <td>{{activity}}</td>
                            </tr>
                        </tbody>
                    </table>

                </td>
                <td>
                    <table class='table table-sm '>
                        <tbody>
                            <tr *ngFor="let date of user.date">
                                <td>{{date | date : 'dd/MM/yyyy hh.mm'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>