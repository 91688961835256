import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import {ActivityUserComponent} from './activity-user/activity-user.component'
import { WelcomescreenUserComponent } from './welcomescreen-user/welcomescreen-user.component';
import { UserpermissionComponent } from './userpermission/userpermission.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-user',
        component: ListUserComponent,
        data: {
          title: "User List",
          breadcrumb: "User List"
        }
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: {
          title: "Create User",
          breadcrumb: "Create User"
        }
      },
      {
        path: 'user-activity',
        component: ActivityUserComponent,
        data: {
          title: "User Activity",
          breadcrumb: "User Activity"
        }
      },
      {
        path: 'onboarding-screen',
        component: WelcomescreenUserComponent,
        data: {
          title: "User Activity",
          breadcrumb: "User Activity"
        }
      },
      {
        path: 'permission/:id',
        component: UserpermissionComponent,
        data: {
          title: "User Permission",
          breadcrumb: "User Permission"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
