<body>

  <div class="row">
    <div class="col-md-6">
      <div class="jesssu-sign-up-wrap">
        <div class="jesssu-sign-up-img">
          <img src="../../../../assets/images/logo.png" alt="" />
          <div class="grow-headline">
            <h4>Submit Your GSTIN <br>and add your Bank Account</h4>
          </div>
          <div class="para-headline">
            <p>Submit your registered GST invoice <br>and add your bank account to get <br>the payments safe and
              securely</p>
          </div>

        </div>
        <div class="jesssu-seller-sign-up-content">
          <div class="jesssu-seller-logo">
            <img src="../../../../assets/images/sign-up-wrap.svg" alt="" />
          </div>

        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="sign-up-gst-section">
        <div class="gst-box">
          <div class="gst-scroll-section">
            <div class="document-upload-section">
              <div class="document-upload-box">
                <div class="image-upload-col">
                  <div class="image-upload-box">
                    <div class="upload-btn-wrapper">
                      <div class="upload-button-row">

                        <div class="image-upload-box">
                          <input type="file" id="pt09" (change)="selectFile($event,'main')"/>
                          <label class="upload-gst-imgbox" for="pt09">
                            <img class="img-width"  src={{userdata.gst_file_link}}
                              onerror="this.onerror=null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                              alt="" />

                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="name-section">
              <h3>GST Invoice no.</h3>
              <input type="text" class="text-input-ph" [(ngModel)]="userdata.gst_number" />
            </div>
            <div class="name-section">
              <h3>Manufacturer Name/Buisness Name</h3>
              <input type="text" class="text-input-ph" (ngModelChange)="verifyManufacturer($event)"
                [(ngModel)]="userdata.manufacturer" />
            </div>
            <div class="name-section">
              <h3>Bank Account number</h3>
              <div class="password-show-row">
                <input type="text" class="text-input-ph" [(ngModel)]="userdata.bank_acc_no" />
              </div>
            </div>
            <div class="name-section">
              <h3>Branch Name</h3>
              <div class="password-show-row">
                <input type="text" class="text-input-ph" [(ngModel)]="userdata.branch_name" />
              </div>
            </div>
            <div class="name-section">
              <h3>IFSC No.</h3>
              <div class="password-show-row">
                <input type="text" class="text-input-ph" [(ngModel)]="userdata.ifsc_code" />
              </div>
            </div>
            <div class="sale-feed-action" *ngIf="ADMIN==null">
              <button type="button" class="sign-action-link" (click)="savegstdata()">Submit</button>
            </div>
            <div class="sale-feed-action" *ngIf="ADMIN==null">
              <button type="button" class="sign-action-link" (click)="skiptodashboard()">Skip for now</button>
            </div>
            <div class="sale-feed-action" *ngIf="ADMIN!=null">
              <button type="button" class="sign-action-link" (click)="skiptodashboard()">View Data</button>
            </div>
            <div class="privacy-policy">
              <div class="policy-box">
                <h6>By clicking the button above you agree to our terms of use & privacy policies</h6>
              </div>
            </div>
            <div class="alert" *ngIf="error">
              <span class="closebtn" (click)="close()">&times;</span>
              <strong>Error!</strong> {{errortext}}
            </div>
            <div class="response" *ngIf="success">
              <span class="closebtn" (click)="close()">&times;</span>
              <strong>Success!</strong> {{successtext}}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>





</body>