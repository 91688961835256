import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.scss']
})
export class CreateMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
