<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                    <!-- <input type="text" name="search" placeholder="Search.." class="search-form-control"> -->
                </div>
                <div class="rightside-profile">
                    <!-- <div class="rightside-img">
                        <img src="../../../../assets/images/bell.svg" />
                    </div> -->
                    <!-- <div class="rightside-profilt-bar-img">
                        <img src={{userdata.gst_file_link}} class="profileimg"/>
                    </div> -->

                    <!-- <div class="image-upload-box-header" style="color:white">
                        <input type="file" id="pt03" style="display:none" />
                        <label class="upload-imgbox-header" for="pt03">
                            <img src={{userdata.profileimage}}
                  onerror="this.onerror==null; this.src='../../../../assets/images/Ellipse 83.png'" alt=""
                  class="profileimg" />
                            <img src="../../../assets/images/black-and-white-boy-casual-555790 (1).png " alt="" />

                        </label>
                        <span>Profile Image</span>
                    </div> -->

                </div>

            </div>
        </div>
        <div class="jesssu-heading">
            <div class="heading-row">
                <!-- <div class="jesssu-heading-name">
                    <h4>User Details</h4>
                </div>
                <div class="contant-row">
                    <img src="../../../assets/images/Home (2).svg" alt="" />
                    <h6>Home -User-User Details</h6>
                </div> -->
            </div>

        </div>





        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h5>User Details</h5>
                </div>
                <div class="card-body">
                    <div class="btn-popup list-user-btn">
                        <a class="btn-action-link1" [routerLink]="'/users/create-user'">Create User</a>
                    </div>
                    <div class="table-responsive">

                        <table class="table table-striped custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">USER INFO</th>
                                    <th scope="col">PHONE NO</th>
                                    <th scope="col">EMAIL</th>
                                    <th scope="col">PERMISSIONS</th>
                                    <th scope="col">CHANGE PERMISSION</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of user_list">

                                    <td>
                                        {{user.firstname}} {{user.lastname}}
                                    </td>
                                    <td>

                                        {{user.phone}}
                                    </td>
                                    <td>
                                        {{user.email}}
                                    </td>
                                    <td>
                                        <div *ngFor="let permission of user.roleaccess">
                                            <a>{{permission}}</a>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" class="btn-action-link1"
                                            (click)="changeaccess(user._id)">Update Access</button>
                                    </td>


                                </tr>



                            </tbody>
                        </table>
                    </div>
                    <!-- <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <ng2-smart-table [settings]="settings" [source]="user_list"></ng2-smart-table>
                </div>
            </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->