import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { Newcustomer, Newsupplier, NotificationModel, ProductModel, Saleorder, Sale_Status, User, UserModel } from '../models/datamodel';


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private webservice: WebService) { }

  // Menu details
  getmenu(payload: Object) {
    return this.webservice.post('menu/getmenu', payload)
  }

  getselectedmenu(payload: Object) {
    return this.webservice.post('menu/getselectedmenu', payload)
  }

  // Add a user component

  addaUser(userModel: UserModel) {
    return this.webservice.post('api/addauser', userModel)
  }

  getownusers(payload: Object) {
    return this.webservice.post('api/getownusers', payload)
  }

  updateSecondaryUserPermission(userId: string, payload: Object) {
    return this.webservice.post(`api/userdetails/update/${userId}`, payload)
  }
  // Login component

  login(userModel: UserModel) {
    return this.webservice.post('api/login', userModel)
  }

  register(userModel: UserModel) {
    return this.webservice.post('api/register', userModel)
  }

  setpassword(userModel: UserModel) {
    return this.webservice.post('api/setpassword', userModel)
  }

  forgotpassword(userModel: UserModel) {
    return this.webservice.put('api/forgotpassword', userModel)
  }

  resetpassword(userModel: UserModel) {
    return this.webservice.put('api/resetpassword', userModel)
  }

  // GST upload Component

  checkManufacturerAvailibility(manufacturerName: Object) {
    return this.webservice.post('api/checkavailibility/manufacturer', manufacturerName)
  }

  getuserdetails(id: string, payload: Object) {
    return this.webservice.post(`api/userdetails/${id}`, payload)
  }

  getuserSubscriptiondetails(payload: Object) {
    return this.webservice.post(`api/user/getsubscriptiondetails`, payload)
  }

  updateuserpaymentaddress(payload: Object) {
    return this.webservice.post(`api/user/updatepaymentaddress`, payload)
  }

  updateusersubscriptiondetails(payload: Object) {
    return this.webservice.post(`api/user/addsubscription`, payload)
  }

  // List User Component
  getsellertoken() {
    return this.webservice.get('api/tokenforseller');
  }

  getusertoken() {
    return this.webservice.get('api/tokenforuser');
  }

  sendnotification(payload: NotificationModel) {
    return this.webservice.post('api/sendnotification', payload)
  }

  sendnotificationuser(payload: NotificationModel) {
    return this.webservice.post1('api/sendnotification', payload)
  }

  getUsers(token) {
    return this.webservice.get(`api/users/${token}`)
  }

  getjessuplatformUsers(token) {
    return this.webservice.get1(`api/jesssu/app/users/${token}`)
  }

  getadminUsers(payload: Object) {
    return this.webservice.post(`api/user/list`, payload)
  }

  updateuser(id: String, userModel: UserModel) {
    return this.webservice.patch(`api/users/${id}`, userModel)

  }

  getuseractivitydetailsappuser(id: String) {
    return this.webservice.get1(`api/details/${id}`)
  }

  onboardingscreen(payload: Object) {
    return this.webservice.post1('api/getuserwisedetails/onboardingscreen', payload)
  }

  addPasswordAndFreeSubscription(payload: Object){
    return this.webservice.postSeller('add/passwordandplan', payload)
  }
  resetSellerPassword(payload: Object){
    return this.webservice.postSeller('api/reset/sellerpassword', payload)
  }
  getPlans(){
    return this.webservice.getSeller(`subscription/getpricingplans`)
  }

  createRazorpayOrder(payload: any) {
    return this.webservice.postSeller(`subscription/addrazorpayorder`, payload)
  }

  addSubscription(payload: any) {
    return this.webservice.postSeller(`subscription/addsubscription`, payload)
  }
  
  addPayment(payload: any) {
    return this.webservice.postSeller(`subscription/addpayment`, payload)

  }

  // Dashboard Component
  // getboxwiseproductskus(payload) {
  //   return this.webservice.post(`jesssu/productplacement/box/productskus`, payload)
  // }

  // getboxwiseproductcount(payload) {
  //   return this.webservice.post(`jesssu/productplacement/box/productcount`, payload)
  // }

  getactiveproductcount(id: String) {
    return this.webservice.get(`jesssu/products/activeproductcount/${id}`)
  }

  getinactiveproductcount(id: String) {
    return this.webservice.get(`jesssu/products/inactiveproductcount/${id}`)
  }

  getactiveproductcountall() {
    return this.webservice.get(`jesssu/products/activeproductcountall`)
  }
  getinactiveproductcountall() {
    return this.webservice.get(`jesssu/products/inactiveproductcountall`)
  }

  getmostShareproduct() {
    return this.webservice.get('api/mostshareproduct')
  }

  getmostLikeproduct() {
    return this.webservice.get('api/mostlikedproduct')
  }

  getlatestsaleorderdatauserwise(id: any, payload: Object) {
    return this.webservice.post(`api/salesorder/user/getlast10/${id}`, payload)
  }

  getlatestsaleorderdataall(payload: Object) {
    return this.webservice.post(`api/salesorder/user/getlast10/all/1234/1234/1234`, payload)
  }

  getlatestsaledata(id: any) {
    return this.webservice.get(`api/sales/user/getlast10/${id}`)
  }
  getlatestsaledataall() {
    return this.webservice.get(`api/sales/user/getlast10/all/1/2/3/4`)
  }

  getorderdatamonthlyweeklytoday(payload: Object) {
    return this.webservice.post(`api/salesorder/dashboard/order`, payload)
  }

  getorderdataforallmonthlyweeklytoday(payload: Object) {
    return this.webservice.post(`api/salesorder/dashboardforall/orderforall`, payload)

  }

  getaddproductcountuserwisemonthwise(payload: Object) {
    return this.webservice.post('jesssu/products/addproductcountbyuser', payload)
  }

  getordercountuserwisemonthwise(payload: Object) {
    return this.webservice.post('api/salesordermonthwiseuserwise', payload)
  }

  getaddproductcountallmonthwise(payload: Object) {
    return this.webservice.post('jesssu/products/addproductcount', payload)
  }
  getordercountallmonthwise(payload: Object) {
    return this.webservice.post('api/salesordermonthwise', payload)
  }

  getAmazonsalescountbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/Amazon/${id}`, payload);
  }

  getFlipkartsalescountbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/Flipkart/${id}`, payload);
  }

  getSnapdealsalescountbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/Snapdeal/${id}`, payload);
  }

  getMeeshosalescountbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/Meesho/${id}`, payload);
  }
  getMarketsalescountbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/Market/${id}`, payload);
  }
  getJesssusalescountbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/Jesssu/${id}`, payload);
  }

  getFlipkartsalescountall(payload: Object) {
    return this.webservice.post(`api/salesorder/count/all/user/all/FlipkartAll`, payload);
  }
  getAmazonsalescountall(payload: Object) {
    return this.webservice.post(`api/salesorder/count/all/user/all/AmazonAll`, payload);
  }
  getSnapdealsalescountall(payload: Object) {
    return this.webservice.post(`api/salesorder/count/all/user/all/SnapdealAll`, payload);
  }
  getMeeshosalescountall(payload: Object) {
    return this.webservice.post(`api/salesorder/count/all/user/all/MeeshoAll`, payload);
  }
  getMarketsalescountall(payload: Object) {
    return this.webservice.post(`api/salesorder/count/all/user/all/MarketAll`, payload);
  }
  getJesssusalescountall(payload: Object) {
    return this.webservice.post(`api/salesorder/count/all/user/all/JesssuAll`, payload);
  }

  getsaleCountforUser(payload: Object, id: String) {
    return this.webservice.post(`api/saleorder/user/wise/salecountallplatform/${id}`, payload);
  }
  getsaleCountforall(payload: Object) {
    return this.webservice.post(`api/saleorder/user/wise/salecountallplatform/for/all`, payload);
  }


  getchannelwisesaleamount(id: any, payload: Object) {
    return this.webservice.post(`api/sales/user/channelwise/view/${id}`, payload)
  }

  getchannelwisesaleamountall(payload: Object) {
    return this.webservice.post(`api/sales/user/channelwise/view/all/1/2/3`, payload)
  }
  getmonthwisesaleamount(id: any) {
    return this.webservice.get(`api/sales/user/channelwiseamount/view/${id}`)
  }

  getaddproductcountuserwise(id: any) {
    return this.webservice.get(`jesssu/products/last30daysaddproductstatus/${id}`)
  }
  getaddproductcountall() {
    return this.webservice.get(`jesssu/products/last30daysaddproductstatus/alluser/123`)
  }

  //Brand Component

  getbrands(payload: Object, offset: number, count: number) {
    return this.webservice.post(`brand/getbrandlist/${offset}/${count}`, payload)
  }
  updatebrand(payload: Object) {
    return this.webservice.post(`brand/brands/statusupdate`, payload)
  }
  getbrandsearch(payload: Object) {
    return this.webservice.post(`brand/search/getbrandlist/0/100`, payload)
  }

  // Brand-edit Component

  getbranddeatils(payload: Object) {
    return this.webservice.post(`brand/brands/getbranddetails`, payload)
  }

  updatesinglebranddetails(payload: Object) {
    return this.webservice.post(`brand/updatesinglebranddetails`, payload)
  }

  // Category Component
  getCategoryList(payload: Object, offset: number, count: number) {
    return this.webservice.post(`category/getcategorylist/${offset}/${count}`, payload)
  }

  getCategorySearch(payload: Object) {
    return this.webservice.post(`category/search/getcategorylist/0/20`, payload)
  }

  // Edit-category component

  getcategorydeatils(payload: Object) {
    return this.webservice.post(`category/category/getcategorydetails/byname`, payload)
  }

  // Add product component

  getAmazonScrapingProduct(asin:String, payload:any) {
    return this.webservice.post(`api/scrape?asin=${asin}&type=seller`, payload)
  }

  getSubcategory(payload: Object) {
    return this.webservice.post(`category/getsubcategory/singlecategory`, payload)
  }

  getproductdetailsbyid(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/singleproduct/${id}`, payload)
  }

  getproductdetailsbySKU(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/code/${id}`, payload)
  }

  updateproductstatusbytoggle(payload: Object) {
    return this.webservice.post(`jesssu/products/updateproductstatus`, payload)

  }

  editproduct(id: String, productModel: ProductModel) {
    return this.webservice.patch(`jesssu/products/products/update/${id}`, productModel)
  }

  editproductvaritation(id: String, payload: Object) {
    return this.webservice.patch(`jesssu/products/products/updatevaritation/${id}`, payload)
  }


  createproduct(productModel: ProductModel) {
    return this.webservice.post('jesssu/products/addproduct', productModel);
  }

  getcategoriesbyId(id: any) {
    return this.webservice.get(`api/usercategories/${id}`);
  }

  getsingleproductdetails(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/details/${id}`, payload)
  }

  // Product import export component
  getuploadproductdetails(offset: any, count: any) {
    return this.webservice.get(`api/seller/uploadproductdetails/${offset}/${count}`)
  }

  getsaledataallforexport(payload: Object) {
    return this.webservice.post(`api/sales/getsaleforexport/manufacturerwise`, payload)
  }


  brandSync(payload: Object) {
    return this.webservice.post('brand/updatebrandlist', payload)
  }

  getproductmaindata(payload: any) {
    return this.webservice.post('jesssu/products/getproductmaindata', payload)
  }

  getproductvariationdata(payload: any) {
    return this.webservice.post('jesssu/products/getproductvaritationdata', payload)
  }

  // Product-list component

  getproductbysupplierwise(payload: Object) {
    return this.webservice.post(`api/purchases/supplierwise/productlist`, payload)
  }

  getnewmodelproductlist(payload: Object, offset: number, count: number) {
    return this.webservice.post(`jesssu/products/getproductforportal/${offset}/${count}`, payload)
  }
  getmissingurlforchannels(payload: any, offset: number, count: number) {
    return this.webservice.get(`jesssu/products/missingurl/${offset}/${count}/${payload}`)
  }

  getnewmodelproductlistparentskucount(payload: Object) {
    return this.webservice.post(`jesssu/products/getproductcountbyparentskuforportal`, payload)
  }

  getnewmodelproductcount(payload: Object) {
    return this.webservice.post(`jesssu/products/totalproductcountnewview`, payload)
  }

  getnewmodelactiveproductcount(payload: Object) {
    return this.webservice.post(`jesssu/products/activeproductcountnewview`, payload)
  }

  getnewmodelinactiveproductcount(payload: Object) {
    return this.webservice.post(`jesssu/products/inactiveproductcountnewview`, payload)
  }

  getnewmodelofferproductlist(payload: Object, offset: any, count: any) {
    return this.webservice.post(`jesssu/products/activeofferproducts/${offset}/${count}`, payload)
  }

  getnewmodelwithoutofferproductlist(payload: Object, offset: any, count: any) {
    return this.webservice.post(`jesssu/products/activewithoutofferproducts/${offset}/${count}`, payload)
  }

  getsearchproductfulldetails(id: any, searchvalue: any) {
    return this.webservice.get(`jesssu/products/getproductdetails/${id}/${searchvalue}`)
  }

  getactiveproductlist(id: any, offset: number, count: number, payload: Object) {
    return this.webservice.post(`jesssu/products/activeproducts/${id}/${offset}/${count}`, payload)
  }

  getinactiveproductlist(id: any, offset: number, count: number, payload: Object) {
    return this.webservice.post(`jesssu/products/inactiveproducts/${id}/${offset}/${count}`, payload)
  }

  inactiveproduct(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/inactive/${id}`, payload)
  }

  activeproduct(id: String, payload: Object) {
    return this.webservice.post(`jesssu/products/products/active/${id}`, payload)
  }

  deleteproduct(id: String) {
    return this.webservice.delete(`jesssu/products/deleteproduct/${id}`)
  }

  addProducttoShopify(payload: Object) {
    return this.webservice.post(`api/shopify/addproduct`, payload)
  }

  deleteProductfromShopify(payload: Object) {
    return this.webservice.post(`api/shopify/deleteproduct`, payload)
  }

  updateProductpriceonShopify(payload: Object) {
    return this.webservice.post(`api/shopify/updateproductprice`, payload)
  }

  // Saleorder component

  getsalebysupplierwise(payload: Object) {
    return this.webservice.post(`api/purchases/supplierwise/salelist`, payload)
  }
  getsalesorderdetailsbyId(id: String, payload: Object) {
    return this.webservice.post(`api/salesorder/single/details/${id}`, payload);
  }

  getsalescountbyId(id: any, payload: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/main/${id}`, payload);
  }

  getsalescountbyIdwithoutDate(id: StringConstructor, post: Object) {
    return this.webservice.post(`api/salesorder/user/wise/count/main/withoutdate/${id}`, post);
  }


  getsalesorderbyId(id: StringConstructor) {
    return this.webservice.get(`api/salesorder/user/view/${id}`);
  }

  getsalesorder() {
    return this.webservice.get('api/salesorder');
  }

  setCompleted(userModel: UserModel) {
    console.log("create user service" + userModel);
    return this.webservice.patch(`users/${userModel._id}`, { 'FirstName': userModel.firstname, 'LastName': userModel.lastname });
  }

  deletevendor(userModel: UserModel) {
    return this.webservice.delete(`users/${userModel._id}`);

  }

  getsalesorderbydateofuser(date1: string, date2: string, id: any, payload: Object) {
    return this.webservice.post(`api/salesorder/user/${date1}/${date2}/${id}`, payload);
  }

  getsalesorderbydate(date1: string, date2: string) {
    return this.webservice.get(`api/salesorder/${date1}/${date2}`);
  }

  getsalesorderbyuserinput(userid: StringConstructor, payload: Object) {
    return this.webservice.post(`api/salesorder/user/selectionwisedata/${userid}`, payload);
  }
  getsearchsalesorder(userid: string, search: string, payload: Object) {
    return this.webservice.post(`api/salesorder/user/view/${userid}/${search}`, payload);
  }

  deletepurchaseorder(id: string) {
    return this.webservice.get(`api/delete/purchaseorder/${id}`)
  }

  // deletepurchase(id:string){
  //   return this.webservice.get(`api/delete/purchase/${id}`,payload)
  // }


  // Saleordertosale component
  createsale(payload: Saleorder) {
    return this.webservice.post('api/addsale', payload)
  }

  updatesaleorder(id: any, payload: Saleorder) {
    return this.webservice.patch(`api/salesorder/single/update/${id}`, payload)
  }
  updatesaleorderpaymentstatus(id: any, payload: Sale_Status) {
    return this.webservice.patch(`api/salesorder/single/updatepaymentforreturn/${id}`, payload)
  }
  updatesaleorderstatus(id: any, payload: Sale_Status) {
    return this.webservice.patch(`api/salesorder/single/updatestatus/${id}`, payload)
  }

  // Supplier component

  getsuppliersbyId(payload: Object, offset: number, count: number) {
    return this.webservice.post(`api/suppliers/${offset}/${count}`, payload);
  }

  getboxbyId(payload: Object) {
    return this.webservice.post(`jesssu/products/getbox`, payload);
  }
  getboxskuproduct(payload: Object, offset: number, count: number) {
    return this.webservice.post(`jesssu/products/getboxskuproduct/${offset}/${count}`, payload);
  }


  getsuppliers() {
    return this.webservice.get('api/suppliers');
  }
  setCompletedforSupplier(userModel: UserModel) {
    console.log("create user service" + userModel);
    return this.webservice.patch(`users/${userModel._id}`, { 'FirstName': userModel.firstname, 'LastName': userModel.lastname });
  }

  deletesupplier(userModel: UserModel) {
    return this.webservice.delete(`users/${userModel._id}`);
  }

  getsupplierdetailsbyid(id: String) {
    return this.webservice.get(`api/suppliers/details/${id}`)
  }

  editsupplier(id: String, supplier: Newsupplier) {
    return this.webservice.patch(`api/suppliers/update/${id}`, supplier)
  }

  createsupplier(supplier: Newsupplier) {

    return this.webservice.post('api/addsupplier', supplier);
  }

  uploadsupplierCsv(file: Object) {
    return this.webservice.post('api/' + `uploadsuppliercsv`, file)
  }

  // Customer component

  getcustomersbyId(payload: Object, offset: number, count: number) {
    return this.webservice.post(`api/customers/${offset}/${count}`, payload);
  }
  getcustomers() {
    return this.webservice.get('api/customers');
  }
  setCompletedforCustomer(userModel: UserModel) {
    console.log("create user service" + userModel);
    return this.webservice.patch(`users/${userModel._id}`, { 'FirstName': userModel.firstname, 'LastName': userModel.lastname });
  }
  deletecustomer(userModel: UserModel) {
    return this.webservice.delete(`users/${userModel._id}`);
  }

  getcustomerdetailsbyid(id: String) {
    return this.webservice.get(`api/customers/details/${id}`)
  }

  editcustomer(id: String, customer: Newcustomer) {
    return this.webservice.patch(`api/customers/update/${id}`, customer)
  }

  createcustomer(customer: Newcustomer) {
    return this.webservice.post('api/addcustomer', customer);
  }

  uploadcustomerCsv(file: Object) {
    return this.webservice.post('api/' + `uploadcustomercsv`, file)
  }

  // Sale-Data Component
  getanytypesalelistbyId(id: any, filter: any) {
    return this.webservice.get(`api/sales/userwise/saledata/anytype/${id}/${filter}`);
  }
  getsalescompletelistbyId(id: StringConstructor) {
    return this.webservice.get(`api/sales/user/view/salecomplete/${id}`);
  }
  getreturnactualproductlistbyId(id: StringConstructor) {
    return this.webservice.get(`api/sales/user/view/productreturn/${id}`);
  }
  getclaimapprovedlistbyId(id: StringConstructor) {
    return this.webservice.get(`api/sales/user/view/claimandapprove/${id}`);
  }
  getclaimrejectedlistbyId(id: StringConstructor) {
    return this.webservice.get(`api/sales/user/view/claimandreject/${id}`);
  }
  getsales() {
    return this.webservice.get('api/sales');
  }

  getsearchsalesforsalecomplete(userid: string, search: string) {
    return this.webservice.get(`api/sales/userwise/saledata/salecomplete/${userid}/${search}`);
  }
  getsearchsalesforactualproductreturn(userid: string, search: string) {
    return this.webservice.get(`api/sales/userwise/saledata/actualproductreturned/${userid}/${search}`);
  }

  getsearchsalesforclaimapproved(userid: string, search: string) {
    return this.webservice.get(`api/sales/userwise/saledata/claimandapprove/${userid}/${search}`);
  }

  getsearchsalesforclaimrejected(userid: string, search: string) {
    return this.webservice.get(`api/sales/userwise/saledata/claimandrejected/${userid}/${search}`);
  }
  getsalesbydateofuser(date1: string, date2: string, id: any) {
    return this.webservice.get(`api/sales/user/${date1}/${date2}/${id}`);
  }
  getsalesbydate(date1: string, date2: string) {
    return this.webservice.get(`api/sales/${date1}/${date2}`);
  }

  getSinglesale(payload: Object) {
    return this.webservice.post('api/sales/getsingle/saledata', payload)
  }
  updatePaymentforaSale(payload: Object) {
    return this.webservice.post('api/addpaymentforsale', payload)
  }


  // Purcahse Component

  getpurchasecountbyIdwithoutDate(id: any) {
    return this.webservice.get(`api/purchases/user/wise/count/main/withoutdate/${id}`);
  }

  addpurchasepayment(payload: Object) {
    return this.webservice.post(`api/purchases/addpayment`, payload)
  }


  getsupplierwisepurchaselist(payload: Object) {
    return this.webservice.post(`api/purchases/supplierwise/purchaselist`, payload)
  }

  getsupplierwisepurchaseorderlist(payload: Object) {
    return this.webservice.post(`api/purchasesorder/supplierwise/purchaselist`, payload)
  }


  getsinglepurchasedetails(id: String, payload: Object) {
    return this.webservice.post(`api/purchases/single/details/${id}`, payload);
  }

  getsinglepurchaseorderdetails(id: String, payload: Object) {
    return this.webservice.post(`api/purchasesorder/single/details/${id}`, payload);
  }


  addpurchase(payload: Object) {
    return this.webservice.post(`api/addpurchase`, payload)
  }

  addpurchaseorder(payload: Object,) {
    return this.webservice.post(`api/addpurchaseorder`, payload,)
  }

  getpurchasebyId(id: StringConstructor) {
    return this.webservice.get(`api/purchases/user/view/${id}`);
  }

  getpurchaseorderbyId(id: StringConstructor, payload: Object) {
    return this.webservice.post(`api/purchasesorder/user/view/${id}`, payload);
  }
  getpurchases(payload: Object) {
    return this.webservice.post('api/purchases', payload);
  }
  getpurchaseorders(payload: Object) {
    return this.webservice.post('api/purchasesorder', payload);
  }



  getpurchasebydateofuser(date1: string, date2: string, id: any, payload: Object) {
    return this.webservice.post(`api/purchases/user/${date1}/${date2}/${id}`, payload);
  }

  getpurchaseorderbydateofuser(date1: string, date2: string, id: any, payload: Object) {
    return this.webservice.post(`api/purchasesorder/user/${date1}/${date2}/${id}`, payload);
  }


  getpurchasesbydate(date1: string, date2: string) {
    return this.webservice.get(`api/purchases/${date1}/${date2}`);
  }

  getpurchasesorderbydate(date1: string, date2: string, payload: Object) {
    return this.webservice.post(`api/purchasesorder/${date1}/${date2}`, payload);
  }

  uploadinvoicetoAws(payload: Object) {
    return this.webservice.post(`api/purchases/uploadinvoice`, payload);
  }

  getproductstatusactiveinactive(payload: Object) {
    return this.webservice.post(`api/saleproduct/addstatus`, payload)

  }
  getpurchaseInvoiceno(payload: String) {
    return this.webservice.get(`api/purchases/1/2/3/4/5/6/7/${payload}`)
  }

  getpurchaseordersearch(userid: string, search: string, payload: Object) {
    return this.webservice.post(`api/purchasesorder/user/view/search/${userid}/${search}`, payload);
  }
  getpurchaselistsearch(userid: string, search: string, payload: Object) {
    return this.webservice.post(`api/purchases/user/view/search/${userid}/${search}`, payload);
  }
  // Add Sale Order Component

  getchannelwisecustomerdetailsbymanufacturer(channel: string, manufacturerid: string) {
    return this.webservice.get(`api/customer/channelwise/${channel}/${manufacturerid}`)
  }

  addsaleorder(payload: Object) {
    return this.webservice.post(`api/addsaleorder`, payload)
  }
  getsaleorderInvoiceno(payload: String) {
    return this.webservice.get(`api/salesorder/1/2/3/4/5/6/7/${payload}`)
  }

  getsupplierwiseSaleOrder(payload: Object) {
    return this.webservice.post(`api/purchases/supplierwise/saleorderlist`, payload)
  }


  // user-seller component

  searchseller(payload: Object) {
    return this.webservice.post(`api/sellers/searchseller`, payload)
  }
  searchuser(payload: Object) {
    return this.webservice.post(`api/users/search/user`, payload)
  }

  // Jesssu coin
  getpendingcoindetails(payload: Object) {
    return this.webservice.post(`api/jesssu/pending/getcoin`, payload)
  }

  approvependingcoin(payload: Object) {
    return this.webservice.post(`api/jesssu/approve/coin`, payload)
  }

  getjesssucoinearningtypes(payload: Object) {
    return this.webservice.post(`api/jesssu/get/coinearntypes`, payload)
  }

  getcontactusdata(offset: any, count: any, token: any) {
    return this.webservice.get(`api/seller/jesssu/contactus/getdata/${offset}/${count}/${token}`)
  }

  getuserfrienddata(offset: any, count: any, payload: Object) {
    return this.webservice.post(`api/seller/jesssu/getfriendlist/user/${offset}/${count}`, payload)
  }


  // Scrapper API 
  scrapeAmazon(payload:Object){
    return this.webservice.postScrapper(`api/getamazondata`, payload)
  }

  scrapeFlipkart(payload:Object){
    return this.webservice.postScrapper(`api/getflipkartdata`, payload)
  }

  scrapeMyntra(payload:Object){
    return this.webservice.postScrapper(`api/getmyntradata`, payload)
  }

  scrapePradipFabrics(payload:Object){
    return this.webservice.postScrapper(`api/getpradipfabricsdata`, payload)
  }

  scrapeLimeroad(payload:Object){
    return this.webservice.postScrapper(`api/getlimeroaddata`, payload)
  }

  scrapeNordstrom(payload:Object){
    return this.webservice.postScrapper(`api/getnordstromdata`, payload)
  }

  scrapeMeesho(payload:Object){
    return this.webservice.postScrapper(`api/getmeeshodata`, payload)
  }
  scrapeTheCrochetCorner(payload:Object){
    return this.webservice.postScrapper(`api/getcrochetcornerdata`, payload)
  }
  scrapeAjio(payload:Object){
    return this.webservice.postScrapper(`api/getajiodata`, payload)
  }
  scrapeProducts(payload:Object){
    return this.webservice.postScrapper(`api/scrapeproducts`, payload)
  }
  getChannels(){
    return this.webservice.getScrapper(`api/getchannels`)
  }

  getAuditData(payload:Object){
    return this.webservice.postScrapper(`api/scrape/getauditdata`, payload)
  }
  getsellerscrapeData(payload:Object){
    return this.webservice.post(`jesssu/products/getscrapestatus`, payload)
  }

  updateAuditData(payload:Object){
    return this.webservice.postScrapper(`api/scrape/updateauditdata`, payload)
  }
  getpopularproduct(payload:Object){
    return this.webservice.post(`jesssu/products/getpopularproduct`, payload)
  }
  getsharedproduct(payload:Object){
    return this.webservice.post(`jesssu/products/getsharedproduct`, payload)
  }
  getuserviewddata(payload:Object){
    return this.webservice.post(`jesssu/products/getuserviewddata`, payload)
  }

}
