import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/service/api.service';
import { io, Socket } from 'socket.io-client';
// const SERVER_URL = 'http://localhost:4001'; // Update with your server URL
const SERVER_URL = 'https://scrapper.jesssu.com'; // Update with your server URL

@Component({
  selector: 'app-syncproduct',
  templateUrl: './syncproduct.component.html',
  styleUrls: ['./syncproduct.component.scss'],
})
export class SyncproductComponent implements OnInit {
  private socket: Socket;
  public sellerdata: any;
  public admindata: any;
  public userdata: any;
  public token: any;
  public pageview = true;
  errortext: string = '';
  successtext: string = '';
  error: boolean = false;
  success: boolean = false;

  selectedChannel: string = '--Select a channel --';
  selectedStatus: boolean = false;
  channelURL: string = '';
  inputText: string = '';
  textArray: string[] = [];
  selectedItems: string[] = [];
  auditData: any[] = [];
  categories: any[] = [];
  public subcategorybox: Boolean = false;
  public categorybox: Boolean = true;
  public genderbox: Boolean = false;

  Primary: string = '';
  InputPrimary: string = '';
  Subcategories: string[] = [];
  Categoryid: string = '';
  Secondary: string = "";
  InputSecondary: string = "";
  Gender: string = '';
  channelList = [];

  constructor(private apiService: ApiService, public router: Router) {
    this.sellerdata = JSON.parse(window.localStorage.getItem('seller-info'));
    this.admindata = JSON.parse(window.localStorage.getItem('admin-info'));
    this.token = JSON.parse(window.localStorage.getItem('TOKEN'));
    if (this.admindata == null) {
      this.router.navigate(['/page-not-found']);
    }
  }

  ngOnInit(): void {
    // this.socket = io(SERVER_URL);
    this.socket = io(SERVER_URL, {
      // Specify CORS options
      transports: ['websocket'],
      withCredentials: true, // Allow credentials (cookies) to be sent cross-origin
      extraHeaders: {
        'Access-Control-Allow-Origin': 'https://admin.jesssu.com', // Set the allowed origin
      }
    });
    this.getChannels();
    this.getAllAuditData();
    this.getCategories();
    // Listen for scrape status updates
    this.socket.on('scrapeStatus', (data) => {
      console.log('Scrape status:', data.status);
      this.success = true;
      this.successtext = data.status;
      // Update your Angular component with the received status
      // You can use a service to communicate between components or update the UI directly
    });
  }
  ngOnDestroy() {
    // Disconnect the socket when the component is destroyed
    this.socket.disconnect();
  }
  getCategories() {
    let payload = {
      token: this.admindata[0].token
    };
    this.apiService.getCategoryList(payload, 0, 1000).subscribe((res: []) => {
      this.categories = res;
    });
  }

  getChannels() {
    this.apiService.getChannels().subscribe((res: []) => {
      this.channelList = res;
    });
  }

  selectChange() {
    this.subcategorybox = true;
    this.genderbox = true;
    this.categories.forEach((i: any) => {
      if (i.categoryname == this.Primary) {
        this.Primary = i.categoryname;
        this.InputPrimary = i.categoryname;
        this.Categoryid = i._id;
      }
    });
    var payload = {
      id: this.Categoryid,
      token: this.admindata[0].token
    };
    this.apiService.getSubcategory(payload).subscribe((res: []) => {
      this.Subcategories = res;
      this.subcategorybox = true;
    });
  }

  selectsubChange() {
    this.InputSecondary = this.Secondary;
    console.log(this.Secondary);
  }

  // Handle the event when a new item is added
  onChannelSelect(event: any) {
    this.closealert();
    this.selectedChannel = event.target.value;
    const currentSelectedChannel = this.channelList.find(sel => sel.channelName === event.target.value);
    this.channelURL = currentSelectedChannel.mainURL
    console.log('Selected Channel:', this.selectedChannel);
  }

  onGenderSelect(event: any) {
    this.closealert();
    this.Gender = event.target.value;
  }

  onStatusSelect(event: any) {
    this.closealert();
    this.selectedStatus = event.target.value;
    console.log('Selected Status:', this.selectedStatus);
  }
  selectFile(event, type) { }
  addTextToList() {
    this.closealert();
    if (this.inputText.trim() !== '') {
      this.textArray.push(this.inputText.trim());
      this.inputText = ''; // Clear the input after adding to the array
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    // Trigger the addTextToList function on Enter key press
    this.addTextToList();
  }

  removeTextFromList(text: string) {
    this.textArray = this.textArray.filter((item) => item !== text);
  }
  onURLChange() {
    this.closealert();
    console.log('Entered Channel URL:', this.channelURL);
  }
  onCategoryChange() {
    this.subcategorybox = true;
    this.genderbox = true;
    let status: Boolean = false;
    this.Primary = this.InputPrimary;
    this.categories.forEach((i: any) => {
      if (i.categoryname == this.Primary) {
        this.Primary = i.categoryname;
        this.Categoryid = i._id;
        status = true;
      }
    });

    if (status === true) {
      var payload = {
        id: this.Categoryid,
        token: this.admindata[0].token
      };
      this.apiService.getSubcategory(payload).subscribe((res: []) => {
        this.Subcategories = res;
      });
    }
  }
  onSubCategoryChange() {
    this.Secondary = this.InputSecondary;
  }
  async addSync() {
    // debugger;
    this.closealert();
    let result = await this.verifyInput();
    if (result === true) {
      const currentDate = new Date();
      for (const i of this.textArray) {
        const payload = {
          useremail: this.admindata[0].email,
          search: i,
          sync: this.selectedStatus,
          url: this.channelURL,
          channel: this.selectedChannel,
          scrapperactivityid: currentDate.getTime().toString(),
          Primary: this.InputPrimary,
          Secondary: this.InputSecondary,
          Gender: this.Gender,
        };
        this.success = true;
        this.successtext = `Request has been submitted successfully for scrape from ${this.selectedChannel}`;
        this.apiService
          .scrapeProducts(payload)
          .subscribe((res: any[]) => { });
      }


      // if (this.selectedChannel === 'The Crochet Corner') {
      //   const currentDate = new Date();
      //   const payload = {
      //     useremail: this.admindata[0].email,
      //     search: "",
      //     sync: this.selectedStatus,
      //     url: this.channelURL,
      //     channel: this.selectedChannel,
      //     scrapperactivityid: currentDate.getTime().toString(),
      //     Primary: "",
      //     Secondary: "",
      //     Gender: "Unisex",
      //   };
      //   this.success = true;
      //   this.successtext = `Request has been submitted successfully for scrape The Crochet Corner`;
      //   this.apiService
      //     .scrapeTheCrochetCorner(payload)
      //     .subscribe((res: any[]) => { });
      // } else {
      //   for (const i of this.textArray) {
      //     const currentDate = new Date();
      //     const payload = {
      //       useremail: this.admindata[0].email,
      //       search: i,
      //       sync: this.selectedStatus,
      //       url: this.channelURL,
      //       channel: this.selectedChannel,
      //       scrapperactivityid: currentDate.getTime().toString(),
      //       Primary: this.InputPrimary,
      //       Secondary: this.InputSecondary,
      //       Gender: this.Gender,
      //     };
      //     if (this.selectedChannel === 'Amazon') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Amazon`;
      //       this.apiService.scrapeAmazon(payload).subscribe((res: any[]) => { });
      //     } else if (this.selectedChannel === 'Flipkart') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Flipkart`;
      //       this.apiService
      //         .scrapeFlipkart(payload)
      //         .subscribe((res: any[]) => { });
      //     } else if (this.selectedChannel === 'Myntra') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Myntra`;
      //       this.apiService.scrapeMyntra(payload).subscribe((res: any[]) => { });
      //     } else if (this.selectedChannel === 'Pradip Fabrics') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Pradip Fabrics`;
      //       this.apiService
      //         .scrapePradipFabrics(payload)
      //         .subscribe((res: any[]) => { });
      //     } else if (this.selectedChannel === 'Limeroad') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Limeroad`;
      //       this.apiService
      //         .scrapeLimeroad(payload)
      //         .subscribe((res: any[]) => { });
      //     } else if (this.selectedChannel === 'Nordstrom') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Nordstrom`;
      //       this.apiService
      //         .scrapeNordstrom(payload)
      //         .subscribe((res: any[]) => { });
      //     } else if (this.selectedChannel === 'Meesho') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Meesho`;
      //       this.apiService.scrapeMeesho(payload).subscribe((res: any[]) => { });
      //     }else if (this.selectedChannel === 'Ajio') {
      //       this.success = true;
      //       this.successtext = `Request has been submitted successfully for scrape ${i} from Ajio`;
      //       this.apiService.scrapeAjio(payload).subscribe((res: any[]) => { });
      //     }
      //   }
      // }
    }
  }

  getAllAuditData() {
    const payload = {
      useremail: this.admindata[0].email,
    };
    this.apiService.getAuditData(payload).subscribe((result: any[]) => {
      this.auditData = result;
    });
  }

  async verifyInput() {

    debugger;
    if (this.selectedChannel === '--Select a channel --') {
      this.error = true;
      this.errortext = 'Please select a channel.';
      return false;
    } else if (this.selectedChannel === 'The Crochet Corner') {
      this.error = false;
      return true;
    } else if (this.channelURL === '') {
      this.error = true;
      this.errortext = 'Please submit channel URL.';
      return false;
    } else if (this.textArray.length === 0) {
      this.error = true;
      this.errortext = 'Please submit one keyword and press enter';
      return false;
    } else if (this.textArray.length === 0) {
      this.error = true;
      this.errortext = 'Please submit one keyword and press enter';
      return false;
    } else if (this.InputPrimary === '' || this.InputPrimary === '') {
      this.error = true;
      this.errortext = 'Please submit category.';
      return false;
    } else if (this.InputSecondary === '' || this.InputSecondary === "--Select subcategory--") {
      this.error = true;
      this.errortext = 'Please submit subcategory.';
      return false;
    } else if (this.Gender === '') {
      this.error = true;
      this.errortext = 'Please select gender.';
      return false;
    } 
    // else {
      // if (this.selectedChannel == 'Amazon') {
      //   if (this.channelURL != 'https://www.amazon.in/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://www.amazon.in/';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'Flipkart') {
      //   if (this.channelURL != 'https://www.flipkart.com') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://www.flipkart.com';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'Pradip Fabrics') {
      //   if (this.channelURL != 'https://pradipfabrics.com/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://pradipfabrics.com/';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'Myntra') {
      //   if (this.channelURL != 'https://www.myntra.com/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://www.myntra.com/';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'Meesho') {
      //   if (this.channelURL != 'https://www.meesho.com/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://www.meesho.com/';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'Nordstrom') {
      //   if (this.channelURL != 'https://www.nordstrom.com/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://www.nordstrom.com/';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'Limeroad') {
      //   if (this.channelURL != 'https://www.limeroad.com/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://www.limeroad.com/';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'The Crochet Corner') {
      //   if (this.channelURL != 'https://the-crochet-corner.mini.store/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://the-crochet-corner.mini.store/';
      //     return false;
      //   }
      // } else if (this.selectedChannel == 'Ajio') {
      //   if (this.channelURL != 'https://www.ajio.com/') {
      //     this.error = true;
      //     this.errortext =
      //       'URL is not correct. Correct URL: https://www.ajio.com/';
      //     return false;
      //   }
      // }
    // }
    return true;
  }

  changeStatus(data: any) {
    const payload = {
      _id: data._id,
      sync: !data.sync,
    };
    this.apiService.updateAuditData(payload).subscribe(() => {
      // Find the index of the object in the array
      const index = this.auditData.findIndex((item) => item._id === data._id);

      // If the object is found, update it in the array
      if (index !== -1) {
        this.auditData[index] = {
          ...this.auditData[index], // Keep the existing properties
          sync: payload.sync, // Update the sync property
        };
      }
    });
  }

  closealert() {
    this.error = false;
    this.success = false;
  }
}
