import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MediaRoutingModule } from './media-routing.module';
import { MediaComponent } from './media/media.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { BrandsComponent } from './brands/brands.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrandEditComponent } from './brands/brand-edit/brand-edit.component';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};


@NgModule({
  declarations: [MediaComponent, BrandsComponent, BrandEditComponent],
  imports: [
    CommonModule,
    MediaRoutingModule,
    DropzoneModule,
    Ng2SmartTableModule,
    InfiniteScrollModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ]
})
export class MediaModule { }
