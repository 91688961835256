import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersellerRoutingModule } from './user-seller-routing.module';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserSellerComponent } from './user-seller.component';


@NgModule({
  declarations: [UserSellerComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    UsersellerRoutingModule,
    FormsModule
  ]
})
export class UserSellerModule { }
