import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  Shownav = true;  
  applestore: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  redirecttoplan() {
    this.router.navigateByUrl("/subscription-selection")
  }
  gotoplaystore(){
    window.open("https://play.google.com/store/apps/details?id=com.seller.jesssu")
  }
  gotoapplestore(){
    this.applestore = true
  }
  closeapplestorepopup(){
    this.applestore = false
  }

  toggglebtn() {  
    this.Shownav = !this.Shownav;  
  }  

}
