import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/service/api.service';


@Component({
  selector: 'app-welcomescreen-user',
  templateUrl: './welcomescreen-user.component.html',
  styleUrls: ['./welcomescreen-user.component.scss']
})
export class WelcomescreenUserComponent implements OnInit {
  [x: string]: any;
  public userDetails = [];
  public id: any;
  public sellerdata: any;
  public payloadforget: any;
  public onBoardingscreenGet: any;
  public onBoardingscreenRemove: any;
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.getOnboarding();
  }

  getOnboarding() {
    this.id = JSON.parse(window.localStorage.getItem("url"));
    if (this.id != null && this.id != '0') {
      const payloadforget = {
        "userid": this.id._id
      }
      this.apiService.onboardingscreen(payloadforget)
        .subscribe((res: any[]) => {
          this.onBoardingscreenGet = res;
        });
    }
  }

  changeStatus(data1, data2) {
    console.log(data1, data2)
    this.id = JSON.parse(window.localStorage.getItem("url"));
    if (this.id != null && this.id != '0') { }

    const payloadforremove = { "userid": data2.user, "deviceid": data2.deviceid, "data": data1 }

    this.productservice.onboardingscreenreset(payloadforremove)
      .subscribe((res: any[]) => {
        this.onBoardingscreenRemove = res;
        location.reload()
      })
  }

}
