<!-- Container-fluid starts-->
<div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Vendor Details</h5>
            </div>
            <div class="card-body vendor-table">
                <div id="batchDelete" class="custom-datatable vendor-list">
                    <div class="table-responsive ">
                            <ng2-smart-table  [settings]="settings" [source]="vendors"></ng2-smart-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
