import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GstuploadComponent } from './gstupload/gstupload.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  {
    path:'login',
    component:LoginComponent,
  },
  {
    path:'auth/gstupload',
    component:GstuploadComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
