<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                    <!-- <input type="text" name="search" placeholder="Search.." class="search-form-control"> -->
                </div>
                <div class="rightside-profile">
                    <!-- <div class="rightside-img">
                        <img src="../../../../assets/images/bell.svg" />
                    </div> -->
                    <!-- <div class="rightside-profilt-bar-img">
                        <img src={{userdata.gst_file_link}} class="profileimg"/>
                    </div> -->

                    <!-- <div class="image-upload-box" style="color:white">
              <input type="file" id="pt03"  style="display:none" />
              <label class="upload-imgbox" for="pt03">
                <img src={{userdata.profileimage}}
                  onerror="this.onerror==null; this.src='../../../../assets/images/Ellipse 83.png'" alt=""
                  class="profileimg" />
                  <img src="../../../assets/images/black-and-white-boy-casual-555790 (1).png " alt="" />
  
              </label>
              <span>Profile Image</span>
            </div> -->

                </div>

            </div>
        </div>
        <div class="jesssu-heading">
            <div class="heading-row">
                <!-- <div class="jesssu-heading-name">
                    <h4>Add User</h4>
                </div>
                <div class="contant-row">
                    <img src="../../../assets/images/Home (2).svg" alt="" />
                    <h6>Home - User-Add User</h6>
                </div> -->
            </div>

        </div>



        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">

                <div class="col-sm-12">
                    <div class="card tab2-card">
                        <div class="create-user-header">
                            <h4> Add User</h4>
                        </div>
                        <div class="card-body tab2-card">
                            <ngb-tabset class="tab-coupon">
                                <ngb-tab title="Add user by submitting the details">
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="accountForm" class="needs-validation user-add" novalida>
                                                <h4>Account Details</h4>

                                                <div class="row">
                                                    <div class="col-md-3 text-right">
                                                        <label class="create-user-product-lable"> First Name</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                            <input type="text" class="create-user-form-control"
                                                                [(ngModel)]="newuserdata.firstname"
                                                                id="validationCustom0" name="gender"
                                                                formControlName="firstname">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-3 text-right">
                                                        <label class="create-user-product-lable"> Last
                                                            Name</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                            <input type="text" class="create-user-form-control"
                                                                id="validationCustom1" required=""
                                                                [(ngModel)]="newuserdata.lastname"
                                                                formControlName="lastname">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-3 text-right">
                                                        <label class="create-user-product-lable"> Email</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                            <input type="text" class="create-user-form-control"
                                                                id="validationCustom2" required=""
                                                                [(ngModel)]="newuserdata.email" formControlName="email">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-3 text-right">
                                                        <label class="create-user-product-lable"> phone</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                            <input type="text" class="create-user-form-control"
                                                                id="validationCustom3" required=""
                                                                [(ngModel)]="newuserdata.phone" formControlName="phone">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-3 text-right">
                                                        <label class="create-user-product-lable"> Password</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                            <input type="text" class="create-user-form-control"
                                                                id="validationCustom4" required=""
                                                                [(ngModel)]="newuserdata.password"
                                                                formControlName="password">
                                                        </div>
                                                    </div>
                                                </div>


                                                <!-- <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                                First Name</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom0"
                                                type="text" required="" [(ngModel)]="newuserdata.firstname"
                                                formControlName="firstname">
                                        </div> -->

                                                <!-- <div class="form-group row">
                                            <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span> Last
                                                Name</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom1"
                                                type="text" required="" [(ngModel)]="newuserdata.lastname"
                                                formControlName="lastname">
                                        </div> -->

                                                <!-- <div class="form-group row">
                                            <label for="validationCustom2" class="col-xl-3 col-md-4"><span>*</span>
                                                Email</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom2"
                                                type="text" required="" [(ngModel)]="newuserdata.email"
                                                formControlName="email">
                                        </div> -->

                                                <!-- <div class="form-group row">
                                            <label for="validationCustom3" class="col-xl-3 col-md-4"><span>*</span>
                                                Phone</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom3"
                                                type="phone" required="" [(ngModel)]="newuserdata.phone"
                                                formControlName="phone">
                                        </div> -->
                                                <!-- <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Password</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom4"
                                                type="password" required="" [(ngModel)]="newuserdata.password"
                                                formControlName="password">
                                        </div> -->
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                            <div class="create-submit-row">

                                <div class="create-user-right">
                                    <button type="button" class="create-user-btn-action-link"
                                        (click)="addUser()">Save</button>
                                </div>
                                <div class="alert" *ngIf="error">
                                    <span class="closebtn"
                                        onclick="this.parentElement.style.display='none';">&times;</span>
                                    <strong>Error!</strong> {{errortext}}
                                </div>
                                <div class="response" *ngIf="success">
                                    <span class="closebtn"
                                        onclick="this.parentElement.style.display='none';">&times;</span>
                                    <strong>Success!</strong> {{successtext}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
        <div *ngIf="usercreatepopup" class="loginbox">
            <div class="paste-source">
                <div class="paste-source-box">
                    <h4>User created sucessfully</h4>
                    <div class="save-btn">
                        <div class="featured-action">
                            <button type="button" class="save-upload-cli-primary-btn"
                                (click)="onusercreatepopupsucess()">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="userdetailspopup" class="loginbox">
            <div class="paste-source">
                <div class="paste-source-box">
                    <h4>Already registered, please use different email/phone</h4>
                    <div class="save-btn">
                        <div class="featured-action">
                            <button type="button" class="save-upload-cli-primary-btn"
                                (click)="onuserdetailspopupsucess()">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>