<div>
    <h4>First Name:{{id.firstname}}; Last Name:{{id.lastname}}</h4>
    <h4>Mobile:{{id.phone}}; Email:{{id.email}}</h4>
</div>

<table class='table table-bordered table-sm '>
    <thead class="thead-dark">
        <tr>
            <th align="center" width="60%">Device Details</th>
            <th align="center" width="40%">Screens</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of onBoardingscreenGet">
            <!-- <td align="center"><input type="checkbox" id="{{user._id}}" value="coupon"
                    (change)="onSelect($event,coupon)"></td> -->
            <td align="center">{{user.deviceid}} </td>
            <td>
                <table class='table table-sm '>
                    <tbody>
                        <tr *ngFor="let activity of user.screens">
                            <!-- <td><div class="product-list-img">
                                <img src={{product.ProductImage}} />
                            </div></td> -->
                            <td align="center">
                                <h3>{{activity}} :->
                                    <button class="btn btn-primary" type="button"
                                        (click)="changeStatus(activity, user)">
                                        Deactivate
                                    </button>
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>

        </tr>
    </tbody>
</table>