<div class="jesssu-seller-banner-wrap">
  <div class="jesssu-seller-img">
    <img src="../../../../assets/images/Rectangle 1493.svg" />
  </div>
  <div class="jesssu-seller-contant">
    <div class="jesssu-header">
      <div class="jesssu-header-row">
        <div>
        </div>
        <div class="rightside-profile">
         
        </div>

      </div>
    </div>
    <!-- <div class="jesssu-heading">
      <div class="heading-row">
        <div class="jesssu-heading-name">
          <h4>Contact Us</h4>
        </div>
        <div class="contant-row">
          <h6>Home - Contact Us</h6>
        </div>
      </div>

    </div> -->



    <div class="user-applications-box">

      <div class="fixTableHead">

        <table class="table table-striped custom-table">
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">EMAIL ID</th>
              <th scope="col">DEVICE ID </th>
              <th scope="col">MESSAGE</th>

            </tr>
          </thead>
          <tbody>
            <tr  *ngFor="let contactus of contactus_list;" infiniteScroll [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="5000" (scrolled)="onScroll()">

              <td align="center" class="contact-us-name-heading">
                <h6>{{contactus.name}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{contactus.email}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{contactus.deviceid}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{contactus.message}}</h6>
              </td>



            </tr>

            <!-- <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Suman Saha </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>suman.saha@gmail.com</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8123876117</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Duis rhoncus dui venenatis consequat porttitor. <br>
                  Etiam aliquet congue consequat. In posuere, <br>
                  nunc sit amet laoreet blandit, urna sapien imperdiet</h6>
              </td>



            </tr>
            <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Surya Basak </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>surya.basak@gmail.com</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8123876117</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Duis rhoncus dui venenatis consequat porttitor. <br>
                  Etiam aliquet congue consequat. In posuere, <br>
                  nunc sit amet laoreet blandit, urna sapien imperdiet</h6>
              </td>



            </tr>
            <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Anirban Das </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Anirban.das@gmail.com</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8123876117</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Duis rhoncus dui venenatis consequat porttitor. <br>
                  Etiam aliquet congue consequat. In posuere, <br>
                  nunc sit amet laoreet blandit, urna sapien imperdiet</h6>
              </td>



            </tr>
            <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Anirban Das </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Anirban.das@gmail.com</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8123876117</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Duis rhoncus dui venenatis consequat porttitor. <br>
                  Etiam aliquet congue consequat. In posuere, <br>
                  nunc sit amet laoreet blandit, urna sapien imperdiet</h6>
              </td>



            </tr> -->

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>