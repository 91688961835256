<!-- <div class="container-fluid" style="display: none;">
    <div class="page-header">
        <div class="row">
            <div class="col-lg-6">
                <div class="page-header-left">
                    <h3>{{title}}
                        <small>Jesssu Admin panel</small>
                    </h3>
                </div>
            </div>
            <div class="col-lg-6">
                <ol class="breadcrumb pull-right">
                    <li class="breadcrumb-item"><a [routerLink]="'/dashboard/default'">
                           <img src="../../../../assets/images/Home (2).svg" alt="" />
                        </a></li>
                    <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}
                    </li>
                    <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">
                        {{breadcrumbs?.childBreadcrumb}}
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div> -->

<div class="container-fluid" style="padding-right: 0px; 
 padding-left: 0px;">
    <div class="page-header" style="padding-top: 0px;
    padding-bottom: 0px; background-color: #172B4D;">
        <div class="row" style="align-items: flex-start;">
            <div class="header-section">
                <div class="page-header-left" style="margin: 0 55px 0;">
                    <h4 style="color: white;font-size: 20px;
                    font-weight: 600;">{{title}}
                        <!-- <small>Jesssu Admin panel</small> -->
                    </h4>
                </div>
            </div>
          
            <div class="breadsecound-section">
                <ol class="breadcrumb pull-left" >
                    <li class="breadcrumb-item"><a [routerLink]="'/dashboard/default'">
                           <img src="../../../../assets/images/Home (2).svg" alt="" />
                        </a></li>
                    <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb" style="color: white;">{{breadcrumbs?.parentBreadcrumb}}
                    </li>
                    <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb" style="color: white;">
                        {{breadcrumbs?.childBreadcrumb}}
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

