<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Update User Permission</h5>

                </div>
                <div class="card-body tab2-card">
                    <div>
                        <h6>Name: {{secondaryuserdata.firstname}} {{secondaryuserdata.lastname}}</h6>
                        <h6>Email: {{secondaryuserdata.email}}</h6>
                        <h6>Phone: {{secondaryuserdata.phone}}</h6>
                    </div>
                    <div class="col-md-6">
                        <ul class="checkbox-items">
                            <li *ngFor="let item of checkboxesDataList">
                                <input type="checkbox" name="" id="" [(ngModel)]="item.isChecked" class="font"
                                    (change)="changeSelection()"> {{item.label}}
                                <br>
                                <!-- <ng-container *ngIf="item.isChecked">
                                <label class="label-field">URL:</label>
                                <div class="form-group">
                                    <input type="text" class="product-form-control" [(ngModel)]="item.url" name="url">
                                </div>
                            </ng-container> -->
                            </li>
                        </ul>
                    </div>
                    <!-- <ngb-tabset class="tab-coupon">
                        <ngb-tab>
                            <ng-template ngbTabTitle>Permission
                                <ng-template ngbTabContent> -->
                    <!-- <form [formGroup]="permissionForm"
                                        class="needs-validation user-add" novalidate="">
                                        <div class="permission-block">
                                            <div class="attribute-blocks">
                                                <h5 class="f-w-600 mb-3">Product Related permission </h5>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Add Product</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani1">
                                                                <input class="radio_animated" id="edo-ani1" type="radio"
                                                                    name="rdo-ani">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani2">
                                                                <input class="radio_animated" id="edo-ani2" type="radio"
                                                                    name="rdo-ani" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Update Product</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani3">
                                                                <input class="radio_animated" id="edo-ani3" type="radio"
                                                                    name="rdo-ani1">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani4">
                                                                <input class="radio_animated" id="edo-ani4" type="radio"
                                                                    name="rdo-ani1" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Delete Product</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani5">
                                                                <input class="radio_animated" id="edo-ani5" type="radio"
                                                                    name="rdo-ani2">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani6">
                                                                <input class="radio_animated" id="edo-ani6" type="radio"
                                                                    name="rdo-ani2" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label class="mb-0 sm-label-radio">Apply discount</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated pb-0">
                                                            <label class="d-block mb-0" for="edo-ani7">
                                                                <input class="radio_animated" id="edo-ani7" type="radio"
                                                                    name="rdo-ani3">
                                                                Allow
                                                            </label>
                                                            <label class="d-block mb-0" for="edo-ani8">
                                                                <input class="radio_animated" id="edo-ani8" type="radio"
                                                                    name="rdo-ani3" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="attribute-blocks">
                                                <h5 class="f-w-600 mb-3">Category Related permission </h5>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Add Category</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani9">
                                                                <input class="radio_animated" id="edo-ani9" type="radio"
                                                                    name="rdo-ani4">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani10">
                                                                <input class="radio_animated" id="edo-ani10"
                                                                    type="radio" name="rdo-ani4" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Update Category</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani11">
                                                                <input class="radio_animated" id="edo-ani11"
                                                                    type="radio" name="rdo-ani5">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani12">
                                                                <input class="radio_animated" id="edo-ani12"
                                                                    type="radio" name="rdo-ani5" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Delete Category</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani13">
                                                                <input class="radio_animated" id="edo-ani13"
                                                                    type="radio" name="rdo-ani6">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani14">
                                                                <input class="radio_animated" id="edo-ani14"
                                                                    type="radio" name="rdo-ani6" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label class="mb-0 sm-label-radio">Apply discount</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0">
                                                            <label class="d-block mb-0" for="edo-ani15">
                                                                <input class="radio_animated" id="edo-ani15"
                                                                    type="radio" name="rdo-ani7">
                                                                Allow
                                                            </label>
                                                            <label class="d-block mb-0" for="edo-ani16">
                                                                <input class="radio_animated" id="edo-ani16"
                                                                    type="radio" name="rdo-ani7" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form> -->
                    <!-- </ng-template>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset> -->
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary" (click)="updateUserPermission()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<div *ngIf="usertstatuspopup" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>Are you sure to update access for this user</h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn" (click)="onuserstatuspopupsucess()">
                        Ok
                    </button>
                </div>
                <div class="featured-action">
                    <button type="button" (click)="onuserstatuscancel()" class="save-upload-cli-primary-btn" id="ok">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
