import { Component, OnInit } from '@angular/core';
import { userListDB } from 'src/app/shared/tables/list-users';
import { ApiService } from '../../../shared/service/api.service';
import { ProductimageService } from '../../../shared/service/fileupload.service';

import { ActivatedRoute, Router } from "@angular/router";
import { UserModel, Accounts } from 'src/app/shared/models/datamodel';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false

  public user_list = []
  sellerdata: any;
  admindata: any;
  user: any;
  subscriptionplan: string;
  token: any;

  constructor(private apiService: ApiService, private router: Router, private imageuploadService: ProductimageService) {

    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptionplan = JSON.parse(window.localStorage.getItem("SUBSCRIPTIONPLAN"));
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    if (this.admindata == null && this.sellerdata == null) {
      this.router.navigate(["'/auth/login'"])
    }
    else {
      if (this.sellerdata != null) {
        if (this.sellerdata[0].accountstatus == "Secondery") {
          let status = this.sellerdata[0].roleaccess.includes("User")
          if (status == false) {
            this.router.navigate(['/page-not-found'])
          }else{
            this.getuserlist()
          }
        }
        else if (this.subscriptionplan == "Free") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Starter") {
          this.router.navigate(['/page-not-found'])
        }
        else if (this.subscriptionplan == "Professional") {
          this.getuserlist()
        }
        else if (this.subscriptionplan == "Enterprise"){
          this.getuserlist()
        }
        else{
          this.router.navigate(['/page-not-found'])
        }
      }
      else {
        this.errortext = "Please select a seller"
        this.error = true
        this.router.navigate(['/all/users-sellers'])
      }
    }


  }

  public settings = {
    columns: {
      avatar: {
        title: 'Avatar',
        type: 'html'
      },
      firstname: {
        title: 'First Name',
      },
      lastname: {
        title: 'Last Name'
      },
      email: {
        title: 'Email'
      },
      phone: {
        title: 'Phone No'
      },
      roleaccess: {
        title: 'Role'
      },
    },
  };

  ngOnInit() {
  }


  getuserlist() {
    if (this.admindata == null) {
      const payload = {
        userid: this.sellerdata[0]._id,
        token: this.token,
        manufacturer: this.sellerdata[0].manufacturer
      }
      this.apiService.getownusers(payload).subscribe((res: UserModel[]) => {
        this.user_list = res
      })
    }
    else {
      const payload = {
        userid: this.sellerdata[0]._id,
        token: this.admindata[0].token,
        manufacturer: this.sellerdata[0].manufacturer
      }
      this.apiService.getownusers(payload).subscribe((res: UserModel[]) => {
        this.user_list = res
      })
    }
  }

  changeaccess(id) {

    this.router.navigate(['/users/permission/' + id]);

  }

}

