import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrandEditComponent } from './brands/brand-edit/brand-edit.component';
import { BrandsComponent } from './brands/brands.component';
import { MediaComponent } from './media/media.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MediaComponent,
        data: {
          title: "Media",
          breadcrumb: "Media"
        }
      },
      {
        path: 'brands',
        component: BrandsComponent,
        data: {
          title: "Brand",
          breadcrumb: "Brand"
        }
      },
      {
        path: 'brands/:brandname',
        component: BrandEditComponent,
        data: {
          title: "Edit-Brand",
          breadcrumb: "Edit-Brand"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MediaRoutingModule { }
