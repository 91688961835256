<p class="scraping-status">{{successtext}}</p>
<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">
                </div>

            </div>
        </div>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body" style="overflow: auto;">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab>
                            <ng-template ngbTabTitle>Scrape Products</ng-template>
                            <ng-template ngbTabContent>

                                <div class="applications-box6">
                                    <div class="applications-table-row">
                                        <div class="col-md-9">
                                            <div class="order-row2">
                                                <h4>Channel Name</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <select name="acc_name" class="submit-form-control"
                                                    [(ngModel)]="selectedChannel" (change)="onChannelSelect($event)">
                                                    <option>--Select a channel --</option>
                                                    <option *ngFor="let channel of channelList">{{channel.channelName}}</option>
                                                    <!-- <option>Flipkart</option>
                                                    <option>Myntra</option>
                                                    <option>Amazon</option>
                                                    <option>Pradip Fabrics</option>
                                                    <option>Nordstrom</option>
                                                    <option>Meesho</option>
                                                    <option>Limeroad</option>
                                                    <option>The Crochet Corner</option> -->
                                                </select>
                                            </div>
                                            <div *ngIf="this.selectedChannel!='The Crochet Corner' ">
                                            <div class="order-row2">
                                                <h4>Enter Channel URL</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <input type="text" placeholder="." class="submit-form-control"
                                                    [(ngModel)]="channelURL" (input)="onURLChange()" disabled/>
                                            </div>
                                           
                                            <div class="order-row2">
                                                <h4>Add Keywords</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <input type="text" placeholder="" [(ngModel)]="inputText"
                                                    class="submit-form-control">
                                                <!-- <button (click)="addTextToList()">+</button> -->
                                                <ul>
                                                    <div *ngIf="textArray.length > 0">
                                                        <div class="text-item"
                                                            *ngFor="let text of textArray; let last = last">
                                                            <span>{{ text }}</span>
                                                            <span *ngIf="!last"></span>
                                                            <!-- Add a comma unless it's the last item -->
                                                            <span class="remove-icon"
                                                                (click)="removeTextFromList(text)">✕</span>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>

                                            <div *ngIf="categorybox">
                                                <div class="order-row2">
                                                    <h4>
                                                        Category Selection*</h4>
                                                </div>
                                                <div class="submit-form-group">
                                                    <select name="acc_name" [(ngModel)]="Primary"
                                                        class="submit-form-control" (change)="selectChange()">
                                                        <option [ngValue]="" copy>--Select category--
                                                        </option>
                                                        <option *ngFor="let category of categories"
                                                            [ngValue]="category.categoryname">
                                                            {{category.gender}} : {{category.categoryname}}
                                                        </option>
                                                    </select>
                                                    <input class="submit-form-control" (input)="onCategoryChange()"
                                                        type="text"
                                                        placeholder="Select category from dropdown or create new."
                                                        [(ngModel)]="InputPrimary">
                                                </div>
                                            </div>
                                            <div *ngIf="subcategorybox">
                                                <div class="order-row2">
                                                    <h4>
                                                        Subcategory Selection*</h4>
                                                </div>

                                                <div class="submit-form-group">
                                                    <select name="acc_name" [(ngModel)]="Secondary"
                                                        class="submit-form-control" (change)="selectsubChange()">
                                                        <option [ngValue]="" copy>--Select subcategory--
                                                        </option>
                                                        <option *ngFor="let subcategory of Subcategories"
                                                            [ngValue]="subcategory.subcategoryname">
                                                            {{subcategory.subcategoryname}}
                                                        </option>
                                                    </select>
                                                    <input class="submit-form-control" (input)="onSubCategoryChange()"
                                                        type="text"
                                                        placeholder="Select sub-category from dropdown or create new."
                                                        [(ngModel)]="InputSecondary">
                                                </div>
                                            </div>
                                            <div *ngIf="genderbox">
                                                <div class="order-row2">
                                                    <h4>
                                                        Gender</h4>
                                                </div>
                                                <div class="submit-form-group">
                                                    <select name="acc_name" class="submit-form-control"
                                                        [(ngModel)]="Gender" (change)="onGenderSelect($event)">
                                                        <option value="">--Select gender --</option>
                                                        <option value="Men">Men</option>
                                                        <option value="Women">Women</option>
                                                        <option value="Unisex">Unisex</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                            <div class="order-row2">
                                                <h4>Active Sync</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <select name="acc_name" class="submit-form-control"
                                                    [(ngModel)]="selectedStatus" (change)="onStatusSelect($event)">
                                                    <option value="false">False</option>
                                                    <option value="true">True</option>
                                                </select>
                                            </div>

                                            <div class="feed-action">

                                                <form #notificationForm="ngForm" id="notificationForm"
                                                    class="needs-validation notification-add" novalidate="">
                                                    <router-outlet></router-outlet>
                                                    <div class="user-submit-row">
                                                        <button class="feed-action-link btn-action-link" type="submit"
                                                            value="Submit" (click)="addSync()">Submit</button>
                                                        <div class="alert" *ngIf="error">
                                                            <span class="closebtn" (click)="closealert()">&times;</span>
                                                            <strong>{{errortext}}</strong>
                                                        </div>
                                                        <div class="response" *ngIf="success">
                                                            <span class="closebtn" (click)="closealert()">&times;</span>
                                                            <strong>{{successtext}}</strong>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </ng-template>
                        </ngb-tab>

                        <ngb-tab>
                            <ng-template ngbTabTitle>Scraping History</ng-template>
                            <ng-template ngbTabContent>


                                <div class="user-applications-box">


                                    <div class="fixTableHead">

                                        <table class="table table-striped custom-table">
                                            <thead>
                                                <tr>

                                                    <th scope="col">USER INFO</th>
                                                    <th scope="col">ACTION</th>
                                                    <th scope="col">CHANNEL</th>
                                                    <th scope="col">SCRAPED PRODUCT</th>
                                                    <th scope="col">PRODUCT UPDATED</th>
                                                    <th scope="col">FAILED COUNT</th>
                                                    <th scope="col">SYNC STATUS</th>
                                                    <th scope="col">COUNT</th>
                                                    <th scope="col">ERROR DETAILS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of auditData">




                                                    <td>
                                                        {{ data.userId | slice:0:10 }}{{ data.userId.length > 10 ? '...'
                                                        : '' }}
                                                    </td>
                                                    <td>
                                                        {{data.actiontype}}
                                                    </td>
                                                    <td>
                                                        {{data.channel}}
                                                    </td>
                                                    <td>
                                                        {{data.totalScraped}}
                                                    </td>
                                                    <td>
                                                        {{data.updateCount}}
                                                    </td>
                                                    <td>
                                                        {{data.failedCount}}
                                                    </td>
                                                    <td>
                                                        <label class="custom-control ios-switch">
                                                            <input type="checkbox" class="ios-switch-control-input"
                                                                id="{{data._id}}" [checked]="data.sync==true"
                                                                lebel="changestatus" (click)="changeStatus(data)"> <span
                                                                class="ios-switch-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {{ data.syncCount - 1 }}
                                                    </td>
                                                    <td>
                                                        {{data.error}}
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>









                </div>
            </div>
        </div>
    </div>
</div>